<template>
  <div>
    <div class="row">
      <div class="col-md-4"><canvas id="report_kategori"></canvas></div>
    </div>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service'
import Chart from 'chart.js/auto'
import ReportsPerKategori from './reports-per-kategori'

export default {
  name: 'ReportSertifikasi',
  data() {
    return {
      cReportsPerKategori: '',
      reportsPerKategori: ReportsPerKategori,
    }
  },
  mounted() {
    const ctx = document.getElementById('report_kategori')
    this.cReportsPerKategori = new Chart(ctx, this.reportsPerKategori)
    this.loadChart()
  },
  methods: {
    async loadChart() {
      await ApiService.get('/sertifikasiperizinan/getChartData').then(({ data }) => {
        this.cReportsPerKategori.data.labels = []
        this.cReportsPerKategori.data.datasets.forEach(dataset => {
          dataset.data = []
        })

        for (const item of data.report) {
          this.cReportsPerKategori.data.labels.push(item.nama_kategori_peralatan)
          this.cReportsPerKategori.data.datasets.forEach(dataset => {
            dataset.data.push(item.total)
          })
        }
        this.cReportsPerKategori.update()
      })
    },
  },
}
</script>
