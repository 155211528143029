export const ReportsPerKategori = {
  type: 'pie',
  data: {
    labels: [],
    datasets: [
      {
        label: 'Reports per Kategori',
        data: [],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(50, 168, 82)',
          'rgb(234, 97, 255)',
        ],
        hoverOffset: 4,
      },
    ],
  },
  options: {
    responsive: true,
    title: {
      display: true,
      text: 'Reports per Kategori',
    },
    legend: {
      position: 'right',
    },
  },
}

export default ReportsPerKategori
