<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Sertifikasi Perizinan'">
        <template v-slot:preview>
          <ReportSertifikasi />
          <b-row><b-col md="12">&nbsp;</b-col></b-row>
          <b-row>
            <b-col md="12" class="mb-2" v-if="akses">
              <b-button variant="outline-dark" @click="$router.push('/sertifikasiperizinan/add')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-ns"
                        size="sm"
                        @input="loadData"
                        v-model="search.nomor_sertifikat"
                        type="search"
                        placeholder="Cari Nomor Sertifikat..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nomor_peralatan"
                        type="search"
                        placeholder="Cari Nomor Equipment..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_peralatan"
                        type="search"
                        placeholder="Cari Nama Peralatan..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-6"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_kategori_peralatan"
                        type="search"
                        placeholder="Cari Kategori Peralatan..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-7"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_lokasi_sertifikasi_perizinan"
                        type="search"
                        placeholder="Cari Lokasi Peralatan..."
                      ></b-form-input>
                    </b-th>
                    <!-- <b-th>
                      <date-picker
                        v-model="search.mulai_berlaku"
                        type="date"
                        value-type="YYYY-MM-DD"
                        range
                        input-class="form-control form-control-sm"
                        placeholder="Pilih tanggal dalam range"
                        @change="loadData"
                        @clear="ClearDateMulai"
                      ></date-picker>
                    </b-th> -->
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.masa_berlaku"
                        type="search"
                        placeholder="Cari Masa Berlaku..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <date-picker
                        v-model="search.berlaku_sampai"
                        type="date"
                        value-type="YYYY-MM-DD"
                        range
                        input-class="form-control form-control-sm"
                        placeholder="Pilih tanggal dalam range"
                        @change="loadData"
                        @clear="ClearDateSampai"
                      ></date-picker>
                    </b-th>
                    <!-- <b-th colspan="1"></b-th> -->
                    <!-- <b-th colspan="1"></b-th> -->
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(berlaku_sampai)="data">
                  {{ moment(data.item.berlaku_sampai) }}
                </template>

                <!-- <template #cell(attachment)="data">
                  <b-button
                    variant="outline-info"
                    size="sm"
                    :href="
                      urlStorage +
                      '/file/sertifikasi/' +
                      data.item.attachment_sertifikat
                    "
                    target="_blank"
                  >
                    <span class="svg-icon svg-icon-sm svg-icon-info">
                      <i class="flaticon2-folder text-info"></i>
                    </span>
                    Lihat Attachment
                  </b-button>
                </template> -->

                <!-- <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button
                      @click="info(data.item, $event.target)"
                    >
                      <div
                        class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1"
                      >
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="akses"
                      @click="
                        $router.push(
                          '/sertifikasiperizinan/edit/' +
                            data.item.id_sertifikasi_perizinan
                        )
                      "
                    >
                      <div
                        class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1"
                      >
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      v-if="akses"
                      @click="toDelete(data)"
                    >
                      <div
                        class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1"
                      >
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template> -->

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
              <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nomor Peralatan :</b></b-col>
                  <b-col>{{ modalInfo.nomor_peralatan }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nomor Sertifikat :</b></b-col>
                  <b-col>{{ modalInfo.nomor_sertifikat }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Nama Peralatan :</b></b-col>
                  <b-col>{{ modalInfo.nama_peralatan }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Kategori Peralatan :</b></b-col>
                  <b-col>{{ modalInfo.nama_kategori_peralatan }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Lokasi :</b></b-col>
                  <b-col>{{ modalInfo.nama_lokasi_sertifikasi_perizinan }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Mulai Berlaku :</b></b-col>
                  <b-col>{{ moment(modalInfo.mulai_berlaku) }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Masa Berlaku :</b></b-col>
                  <b-col>{{ modalInfo.masa_berlaku }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Berlaku Sampai :</b></b-col>
                  <b-col>{{ moment(modalInfo.berlaku_sampai) }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Disahkan Oleh :</b></b-col>
                  <b-col>{{ modalInfo.disahkan_oleh }}</b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-right"><b>Attachment :</b></b-col>
                  <b-col>
                    <b-button
                      variant="outline-info"
                      size="sm"
                      :href="urlStorage + '/file/sertifikasi/' + modalInfo.attachment_sertifikat"
                      target="_blank"
                    >
                      <span class="svg-icon svg-icon-sm svg-icon-info">
                        <i class="flaticon2-folder text-info"></i>
                      </span>
                      Lihat Attachment
                    </b-button>
                  </b-col>
                </b-row>

                <hr />
                <b-row class="mb-2">
                  <b-col sm="3" class="text-sm-center">
                    <h6>History Sertifikasi</h6>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3"></b-col>
                  <b-col>
                    <ol>
                      <li v-for="(todo, index) in modalInfo.history" v-bind:key="todo.index">
                        <b-row>
                          <b-col sm="5">
                            <span>{{ moment(modalInfo.history[index].tanggal_pemeriksaan) }}</span>
                          </b-col>
                          <b-col>
                            <b-button
                              class="mt-2"
                              variant="primary"
                              size="sm"
                              :href="
                                urlStorage +
                                  '/file/history_sertifikasi/' +
                                  modalInfo.history[index].attachment_history
                              "
                              target="_blank"
                            >
                              <span class="svg-icon svg-icon-sm svg-icon-light">
                                <i class="flaticon-download text-info"></i>
                              </span>
                            </b-button>
                          </b-col>
                        </b-row>
                      </li>
                    </ol>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <a
                      v-if="akses"
                      @click="
                        $router.push(
                          '/sertifikasiperizinan/edit/' + modalInfo.id_sertifikasi_perizinan
                        )
                      "
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </a>
                    <a v-if="akses" @click="toDelete(modalInfo)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </a>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <br />
          <b-row class="ml-1">
            <b-alert show variant="primary">
              <div class="pl-2">
                <h3>Export Excel:</h3>
                <ul>
                  <li>Filter merujuk berdasarkan tabel diatas.</li>
                  <li>
                    Jika anda tidak melakukan filter pada tabel diatas, maka akan mengekspor semua
                    data.
                  </li>
                </ul>
                <export-excel
                  :data="json_data"
                  :fields="json_fields"
                  :name="'Sertifikasi Perizinan.xls'"
                >
                  <b-button
                    variant="light"
                    class="text-dark"
                    :disabled="loading"
                    @click="loadDataExport"
                  >
                    <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                    <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                      <i class="flaticon-download"></i>
                    </span>
                    Export
                  </b-button>
                </export-excel>
              </div>
            </b-alert>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import ReportSertifikasi from '@/components/sertifikasi/Report.vue'
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import * as moment from 'moment'
import 'moment/locale/id'
export default {
  data() {
    return {
      sortBy: 'nomor_peralatan',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'nomor_sertifikat', label: 'Nomor Sertifikat', sortable: true },
        { key: 'nomor_peralatan', label: 'Nomor Peralatan', sortable: true },
        { key: 'nama_peralatan', label: 'Nama Peralatan', sortable: true },
        {
          key: 'nama_kategori_peralatan',
          label: 'Kategori Peralatan',
          sortable: true,
        },
        {
          key: 'nama_lokasi_sertifikasi_perizinan',
          label: 'Lokasi Peralatan',
          sortable: true,
        },
        // {
        //   key: "mulai_berlaku",
        //   label: "Mulai Berlaku",
        //   sortable: true,
        //   formatter: (value) => {
        //     return this.moment(value);
        //   },
        // },
        { key: 'masa_berlaku', label: 'Masa Berlaku', sortable: true },
        {
          key: 'berlaku_sampai',
          label: 'Berlaku Sampai',
          sortable: true,
        },
        // { key: "attachment", label: "Attachment" },
        // { key: "actions", label: "Actions" },
      ],
      search: {
        nomor_sertifikat: '',
        nomor_peralatan: '',
        nama_peralatan: '',
        nama_kategori_peralatan: '',
        nama_lokasi_sertifikasi_perizinan: '',
        mulai_berlaku: ['', ''],
        masa_berlaku: '',
        berlaku_sampai: ['', ''],
      },
      modalInfo: {
        id_sertifikasi_perizinan: null,
        nomor_sertifikat: null,
        nomor_peralatan: null,
        nama_peralatan: null,
        nama_kategori_peralatan: null,
        nama_lokasi_sertifikasi_perizinan: null,
        mulai_berlaku: null,
        masa_berlaku: null,
        berlaku_sampai: null,
        disahkan_oleh: null,
        attachment_sertifikat: null,
        history: [],
      },
      json_fields: {
        No: 'no',
        'Nomor Peralatan': 'nomor_peralatan',
        'Nama Peralatan': 'nama_peralatan',
        'Kategori Peralatan': 'nama_kategori_peralatan',
        'Lokasi Peralatan': 'nama_lokasi_sertifikasi_perizinan',
        'Mulai Berlaku': 'mulai_berlaku',
        'Masa Berlaku': 'masa_berlaku',
        'Berlaku Sampai': 'berlaku_sampai',
      },
      json_data: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],

      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      loading: false,
      dialog: false,
      akses: false,

      urlStorage: '',
    }
  },
  components: {
    ReportSertifikasi,
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sertifikasi Perizinan', route: '' },
      { title: 'Sertifikasi Perizinan' },
    ])
  },
  created() {
    moment.locale('id')
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/sertifikasiperizinan?page=' +
        this.currentPage +
        '&nomor_sertifikat=' +
        this.search.nomor_sertifikat +
        '&nomor_peralatan=' +
        this.search.nomor_peralatan +
        '&nama_peralatan=' +
        this.search.nama_peralatan +
        '&nama_kategori_peralatan=' +
        this.search.nama_kategori_peralatan +
        '&nama_lokasi_sertifikasi_perizinan=' +
        this.search.nama_lokasi_sertifikasi_perizinan +
        // "&mulai_berlaku_start=" +
        // this.search.mulai_berlaku[0] +
        // "&mulai_berlaku_end=" +
        // this.search.mulai_berlaku[1] +
        '&masa_berlaku=' +
        this.search.masa_berlaku +
        '&berlaku_sampai_start=' +
        this.search.berlaku_sampai[0] +
        '&berlaku_sampai_end=' +
        this.search.berlaku_sampai[1] +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.akses = data.akses
          self.items = data.data.data
          self.items.forEach((row, index) => {
            if (self.notif(row.berlaku_sampai).includes('lalu')) {
              self.items[index]['_rowVariant'] = 'danger'
            }
          })
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.loadDataExport()
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    info(item, button) {
      this.modalInfo.id_sertifikasi_perizinan = item.id_sertifikasi_perizinan
      this.modalInfo.nomor_sertifikat = item.nomor_sertifikat
      this.modalInfo.nomor_peralatan = item.nomor_peralatan
      this.modalInfo.nama_peralatan = item.nama_peralatan
      this.modalInfo.nama_kategori_peralatan = item.nama_kategori_peralatan
      this.modalInfo.nama_lokasi_sertifikasi_perizinan = item.nama_lokasi_sertifikasi_perizinan
      this.modalInfo.mulai_berlaku = item.mulai_berlaku
      this.modalInfo.masa_berlaku = item.masa_berlaku
      this.modalInfo.berlaku_sampai = item.berlaku_sampai
      this.modalInfo.disahkan_oleh = item.disahkan_oleh
      this.modalInfo.attachment_sertifikat = item.attachment_sertifikat
      this.modalInfo.history = item.history
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.id_sertifikasi_perizinan = null
      this.modalInfo.nomor_sertifikat = null
      this.modalInfo.nomor_peralatan = null
      this.modalInfo.nama_peralatan = null
      this.modalInfo.nama_kategori_peralatan = null
      this.modalInfo.nama_lokasi_sertifikasi_perizinan = null
      this.modalInfo.mulai_berlaku = null
      this.modalInfo.masa_berlaku = null
      this.modalInfo.berlaku_sampai = null
      this.modalInfo.disahkan_oleh = null
      this.modalInfo.attachment_sertifikat = null
      this.modalInfo.history.splice(0, this.modalInfo.history.length)
    },
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD').format('dddd, LL')
    },
    notif(tanggal) {
      return moment(tanggal, 'YYYY-MM-DD').fromNow()
    },
    modalShow(content) {
      this.contentModal = content
      this.modal = true
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    ClearDateMulai() {
      this.search.mulai_berlaku[0] = null
      this.search.mulai_berlaku[1] = null
    },
    ClearDateSampai() {
      this.search.berlaku_sampai[0] = null
      this.search.berlaku_sampai[1] = null
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/sertifikasiperizinan?page=' +
          this.currentPage +
          '&nomor_sertifikat=' +
          this.search.nomor_sertifikat +
          '&nomor_peralatan=' +
          this.search.nomor_peralatan +
          '&nama_peralatan=' +
          this.search.nama_peralatan +
          '&nama_kategori_peralatan=' +
          this.search.nama_kategori_peralatan +
          '&nama_lokasi_sertifikasi_perizinan=' +
          this.search.nama_lokasi_sertifikasi_perizinan +
          // "&mulai_berlaku_start=" +
          // this.search.mulai_berlaku[0] +
          // "&mulai_berlaku_end=" +
          // this.search.mulai_berlaku[1] +
          '&masa_berlaku=' +
          this.search.masa_berlaku +
          '&berlaku_sampai_start=' +
          this.search.berlaku_sampai[0] +
          '&berlaku_sampai_end=' +
          this.search.berlaku_sampai[1] +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.items.forEach((row, index) => {
              if (self.notif(row.berlaku_sampai).includes('lalu')) {
                self.items[index]['_rowVariant'] = 'danger'
              }
            })
            self.totalPerPage = data.data.data.length
            self.loadDataExport()
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    async loadDataExport() {
      var self = this
      self.loading = true

      await this.timeout(100)
      ApiService.get(
        '/sertifikasiperizinan/export?page=' +
          this.currentPage +
          '&nomor_peralatan=' +
          this.search.nomor_peralatan +
          '&nama_peralatan=' +
          this.search.nama_peralatan +
          '&nama_kategori_peralatan=' +
          this.search.nama_kategori_peralatan +
          '&nama_lokasi_sertifikasi_perizinan=' +
          this.search.nama_lokasi_sertifikasi_perizinan +
          // "&mulai_berlaku_start=" +
          // this.search.mulai_berlaku[0] +
          // "&mulai_berlaku_end=" +
          // this.search.mulai_berlaku[1] +
          '&masa_berlaku=' +
          this.search.masa_berlaku +
          '&berlaku_sampai_start=' +
          this.search.berlaku_sampai[0] +
          '&berlaku_sampai_end=' +
          this.search.berlaku_sampai[1]
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.json_data.splice(0, self.json_data.length)
            data.data.forEach((row, index) => {
              self.json_data.push({
                no: index + 1,
                nomor_peralatan: row.nomor_peralatan,
                nama_peralatan: row.nama_peralatan,
                nama_kategori_peralatan: row.nama_kategori_peralatan,
                nama_lokasi_sertifikasi_perizinan: row.nama_lokasi_sertifikasi_perizinan,
                mulai_berlaku: row.mulai_berlaku,
                masa_berlaku: row.masa_berlaku,
                berlaku_sampai: row.berlaku_sampai,
              })
            })
            self.loading = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.id_sertifikasi_perizinan
      // var index = row.index;
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/sertifikasiperizinan/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.$nextTick(() => {
                      self.$bvModal.hide('info-modal')
                    })
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
